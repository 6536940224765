import IProducts from '@/module/main/dto/IProducts';
import IProduct from '@/module/main/dto/IProduct';

export default class ProductsModel implements IProducts {
  constructor(
    public data: IProduct[],
    public pageCount: number,
    public outOf: number,
  ) {}

  private _currentPage = 1;
  private _first = 0;
  private _last = 0;

  get first() {
    return 9 * this.currentPage - 8;
  }

  get last(): number {
    const fullPage = this.currentPage * 9;
    if (fullPage > this.outOf) {
      return this.outOf;
    }
    return fullPage;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }
}
