import BasePath from '@/module/api/service/BasePath';

export default {
  products: new BasePath('categories/$id/products?perPage=$perPage&page=$page'),
  product: new BasePath('products/$id'),
  categories: new BasePath('categories?onlyRootCategory=false'),
  categoriesStatus: new BasePath('categories/statusData'),
  subcategories: new BasePath('categories/$id/subcategories'),
  category: new BasePath('categories/$id'),
  files: new BasePath('/files/$id/content'),
  shops: new BasePath('shops'),
  banners: new BasePath('banners'),
  order: new BasePath('categories/$id/products?sortBy=price&dir=$type&page=$page'),
  paging: new BasePath('categories/$id/products?page=$page'),
  search: new BasePath('products/search?text=$query&page=$page'),
  defaultRequest: new BasePath('/categories/$id/products?seller=$sellers&brand=$brands'
    + '&inCategories=$categories&minPrice=$priceMin&maxPrice=$priceMax&minMonthly=$monthlyMin'
    + '&maxMonthly=$monthlyMax&page=$page&sortBy=$sort&dir=$type'),
};
