import LocalStorageAccessor, { StoredCategoriesWithStatus } from "@/app/utils/LocalStorageAccessor";

import Api from "@/module/api/service/Api";
import CategoriesModel from "../model/CategoriesModel";
import ICategories from "../dto/ICategories";
import ICategoriesStatus from "../dto/ICategoriesStatus";
import ICategory from "../dto/ICategory";
import { getCategoriesStatusInterval } from "@/app/utils/EnvConfig";

export type CategoriesWithStatus = { categories: CategoriesModel, lastUpdate: Date, lastChecked: Date };

class CategoriesService {
  private readonly MILLISECONDS_PER_MINUTE = 60000;

  public async getCategoriesWithStatus(lastChecked?: Date, lastUpdate?: Date): Promise<CategoriesWithStatus | undefined> {
    const categoriesStatus: ICategoriesStatus | null = await Api.categoriesStatus();
    const categoriesFromStorage: StoredCategoriesWithStatus | undefined = LocalStorageAccessor.loadCategories();

    if (this.shouldFetchCategories(categoriesStatus, lastChecked ?? categoriesFromStorage?.lastCheck, lastUpdate ?? categoriesFromStorage?.lastUpdate)) {
      const newCategories: ICategories | null = await this.fetchCategories();
      if (!newCategories)
        return;
      
      LocalStorageAccessor.storeCategories(new CategoriesModel(newCategories.data), lastUpdate ?? categoriesStatus?.lastUpdate, new Date());
      return this.createCategoriesWithStatus(newCategories.data, lastUpdate ?? categoriesStatus?.lastUpdate);
    }

    return this.createCategoriesWithStatus(categoriesFromStorage?.categories?.data, lastUpdate ?? categoriesStatus?.lastUpdate);
  }

  private shouldFetchCategories(categoriesStatus: ICategoriesStatus | null, lastChecked?: Date, lastUpdate?: Date): boolean {
    return !lastChecked || lastChecked.valueOf() + getCategoriesStatusInterval() * this.MILLISECONDS_PER_MINUTE < Date.now() ||
      !categoriesStatus || !lastUpdate || categoriesStatus.lastUpdate > lastUpdate;
  }

  private async fetchCategories(): Promise<ICategories | null> {
    const newCategories: ICategories | null = await Api.categories();
    newCategories?.data.sort((a, b) => b.priority - a.priority);
    return newCategories;
  }

  private createCategoriesWithStatus(categories: ICategory[] | undefined, lastUpdate: Date | undefined): CategoriesWithStatus {
    return {
      categories: new CategoriesModel(categories ?? []),
      lastUpdate: lastUpdate ?? new Date(0),
      lastChecked: new Date(),
    };
  }
}

export default new CategoriesService();
