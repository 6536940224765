import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import App from './app/App.vue';
import { HcLocalizerVuePlugin } from '@hc-hefaistos/hc-localization';
import Vue from 'vue';
import { getCulture } from "./app/utils/EnvConfig";
import router from './app/router';
import store from './app/store';
import translationsCsCz from '@/translations/cs-CZ.json';
import translationsSkSk from '@/translations/sk-SK.json';

Vue.config.productionTip = false;

const localizerOptions = {
  defaultCulture: getCulture(),
  translations: getCulture() == "sk-SK" ? translationsSkSk : translationsCsCz
};

// TODO: temporary workaround, because hc-localization plugin is already imported and used in
//  hc-components plugin and using here (without object copy) not triggering installation.
Vue.use(Object.create(HcLocalizerVuePlugin), localizerOptions);

Vue.use(HcLocalizerVuePlugin, localizerOptions);

Vue.use(BootstrapVue);

Vue.use(BootstrapVueIcons);

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default vue;
