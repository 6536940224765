import CategoriesService, { CategoriesWithStatus } from '@/module/main/service/CategoriesService';

import CategoriesModel from '@/module/main/model/CategoriesModel';
import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

interface IState {
  categories: CategoriesModel | undefined,
  categoriesLastUpdate: Date | undefined;
  categoriesLastCheck: Date | undefined;
  shops: [],
  banners: [],
  breadcrumbs: IBreadcrumb[],
}

export default new Vuex.Store<IState>({
  state: {
    categories: undefined,
    categoriesLastUpdate: undefined,
    categoriesLastCheck: undefined,
    shops: [],
    banners: [],
    breadcrumbs: [],
  },
  mutations: {
    setShops(state, shops) {
      state.shops = shops;
    },
    setBanners(state, banners) {
      state.banners = banners;
    },
    setCategories(state: IState, categories: CategoriesModel) {
      state.categories = categories;
    },
    setCategoriesLastUpdate(state: IState, lastUpdate: Date) {
      state.categoriesLastUpdate = lastUpdate;
    },
    setCategoriesLastCheck(state: IState, lastCheck: Date) {
      state.categoriesLastCheck = lastCheck;
    },
    setBreadcrumbs(state: IState, breadcrumbs: IBreadcrumb[]) {
      state.breadcrumbs = breadcrumbs;
    },
  },
  actions: {
    async getCategories(ctx: any) {
      if (!this.state.categories?.count) {
        const categoriesWithStatus: CategoriesWithStatus | undefined = await CategoriesService
          .getCategoriesWithStatus(this.state.categoriesLastCheck, this.state.categoriesLastUpdate);
        if (categoriesWithStatus) {
          ctx.commit('setCategories', categoriesWithStatus.categories);
          ctx.commit('setCategoriesLastUpdate', categoriesWithStatus.lastChecked);
          ctx.commit('setCategoriesLastCheck', categoriesWithStatus.lastChecked);
        }
      }
    }
  },
  modules: {
  },
  getters: {
  }
});
