import { IPath, ITalkie, Talkie } from '@hc-hefaistos/hc-talk-talk';

import CategoriesModel from '@/module/main/model/CategoriesModel';
import IBanners from '@/module/main/dto/IBanners';
import ICategories from '@/module/main/dto/ICategories';
import ICategoriesStatus from '@/module/main/dto/ICategoriesStatus';
import IProduct from '@/module/main/dto/IProduct';
import IProducts from '@/module/main/dto/IProducts';
import IShop from '@/module/main/dto/IShop';
import IShops from '@/module/main/dto/IShops';
import Paths from '@/module/api/service/Paths';
import ProductsModel from '@/module/main/model/ProductsModel';
import { getApiClientId } from "@/app/utils/EnvConfig";

class Api {
  private readonly apiClient: ITalkie;

  constructor() {
    this.apiClient = new Talkie();
  }

  public async categories(): Promise<ICategories | null> {
    let categoriesModelResponse: ICategories | null = null;
    const path: IPath = Paths.categories;
    await this.apiClient.get<ICategories>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          categoriesModelResponse = new CategoriesModel(response.data);
        }
      });
    return new Promise((resolve) => {
      resolve(categoriesModelResponse);
    });
  }

  public async categoriesStatus(): Promise<ICategoriesStatus | null> {
    let statusResponse: ICategoriesStatus | null = null;
    const path: IPath = Paths.categoriesStatus;
    await this.apiClient.get<ICategoriesStatus>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          statusResponse = response;
        }
      })
      .catch(() => {});
    return statusResponse;
  }

  public async subcategories(categoryId: number): Promise<ICategories | null> {
    let categoriesModelResponse: ICategories | null = null;
    const path: IPath = Paths.subcategories;
    path.primary = (path.secondary ? path.secondary : path.primary)
      .replace('$id', categoryId.toString());
    await this.apiClient.get<ICategories>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          categoriesModelResponse = new CategoriesModel(response.data);
        }
      });
    return new Promise((resolve) => {
      resolve(categoriesModelResponse);
    });
  }

  public async category(categoryId: number): Promise<any | null> {
    let categoriesModelResponse: any | null = null;
    const path: IPath = Paths.category;
    path.primary = (path.secondary ? path.secondary : path.primary)
      .replace('$id', categoryId.toString());
    await this.apiClient.get<any>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          categoriesModelResponse = response.data;
        }
      });
    return new Promise((resolve) => {
      resolve(categoriesModelResponse);
    });
  }

  public async files(imageId: number): Promise<string | null> {
    let path: string = Paths.files.primaryBaseUrl + Paths.files.primary;
    path = path.replace('$id', imageId.toString());
    let objectURL: string;

    await fetch(path, {
      headers: this.keyHeaders,
    }).then((response) => response.blob())
      .then((myBlob) => {
        objectURL = URL.createObjectURL(myBlob);
      });
    return new Promise((resolve) => {
      resolve(objectURL);
    });
  }

  public async shops(): Promise<IShop[] | null> {
    let shops: IShop[] | null = null;
    const path: IPath = Paths.shops;
    await this.apiClient.get<IShops>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          shops = response.data;
        }
      });
    return new Promise((resolve) => {
      resolve(shops);
    });
  }

  public async banners(): Promise<IBanners | null> {
    let banners: IBanners | null = null;
    const path: IPath = Paths.banners;
    await this.apiClient.get<IBanners>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          banners = response;
        }
      });
    return new Promise((resolve) => {
      resolve(banners);
    });
  }

  public async products(categoryId: number, page: number = 1, perPage: number = 9): Promise<IProducts | null> {
    let productsModelResponse: IProducts | null = null;
    const path: IPath = Paths.products;
    path.primary = (path.secondary ? path.secondary : path.primary)
      .replace('$id', categoryId.toString())
      .replace('$perPage', perPage.toString())
      .replace('$page', page.toString());
    await this.apiClient.get<IProducts>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          productsModelResponse = new ProductsModel(
            response.data,
            response.pageCount,
            response.outOf,
          );
        }
      });
    return new Promise((resolve) => {
      resolve(productsModelResponse);
    });
  }

  public async product(productId: string): Promise<IProduct | null> {
    const path: IPath = Paths.product;
    path.primary = (path.secondary ? path.secondary : path.primary)
      .replace('$id', productId);
    return this.apiClient.get<IProduct>(path, undefined, this.keyHeaders);
  }

  public async search(query: string, page: number): Promise<IProducts | null> {
    let productsModelResponse: IProducts | null = null;
    const path: IPath = Paths.search;
    path.primary = (path.secondary ? path.secondary : path.primary)
      .replace('$query', encodeURIComponent(query))
      .replace('$page', page.toString());
    await this.apiClient.get<IProducts>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          productsModelResponse = new ProductsModel(
            response.data,
            response.pageCount,
            response.outOf,
          );
        }
      });
    return new Promise((resolve) => {
      resolve(productsModelResponse);
    });
  }

  public async defaultRequest(
    categoryId: number, filterData: any, page: number, type: string,
  ): Promise<IProducts | null> {
    let productsModelResponse: IProducts | null = null;
    const path: IPath = Paths.defaultRequest;

    if (filterData) {
      const brandsString = filterData.brands.length > 0 ? filterData.brands.toString() : '';
      const sellersString = filterData.sellers.length > 0 ? filterData.sellers.toString() : '';
      const categoriesString = filterData.categories.length > 0
        ? filterData.categories.toString() : '';
      path.primary = (path.secondary ? path.secondary : path.primary)
        .replace('$id', categoryId.toString())
        .replace('$brands', brandsString)
        .replace('$sellers', sellersString)
        .replace('$categories', categoriesString)
        .replace('$priceMin', filterData.priceMin.toString())
        .replace('$priceMax', filterData.priceMax.toString())
        .replace('$monthlyMin', filterData.monthlyMin.toString())
        .replace('$monthlyMax', filterData.monthlyMax.toString())
        .replace('$type', type)
        .replace('$sort', type === '' ? 'name' : 'price')
        .replace('$page', page.toString());
    } else {
      path.primary = (path.secondary ? path.secondary : path.primary)
        .replace('$id', categoryId.toString())
        .replace('$brands', '')
        .replace('$sellers', '')
        .replace('$categories', '')
        .replace('$priceMin', '')
        .replace('$priceMax', '')
        .replace('$monthlyMin', '')
        .replace('$monthlyMax', '')
        .replace('$type', type)
        .replace('$sort', type === '' ? 'name' : 'price')
        .replace('$page', page.toString());
    }
    await this.apiClient.get<IProducts>(path, undefined, this.keyHeaders)
      .then((response) => {
        if (response) {
          productsModelResponse = new ProductsModel(
            response.data,
            response.pageCount,
            response.outOf,
          );
        }
      });
    return new Promise((resolve) => {
      resolve(productsModelResponse);
    });
  }

  private get keyHeaders(): { [fieldName: string]: string } {
    return {
      apiKey: getApiClientId(),
    };
  }
}

export default new Api();
