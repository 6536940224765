import { Path, HcSparrowHawk } from '@hc-hefaistos/hc-talk-talk';
import { getApiBaseUrl } from "@/app/utils/EnvConfig";

export default class BasePath extends Path {
  constructor(primary: string, useAuthorizationToken = false) {
    super({
      primary,
      secondary: primary,
      primaryBaseUrl: getApiBaseUrl(),
      secondaryBaseUrl: `http://localhost:${HcSparrowHawk.port}/api/loki/`,
      useAuthorizationToken,
    });
  }
}
