function getEnv(name: string): string {
  // @ts-ignore
  return window?.envConfig?.[name] || process.env[name] || "";
}

function getApiBaseUrl(): string {
  return getEnv("VUE_APP_API_BASE_URL");
}

function getCulture(): string {
  return getEnv("VUE_APP_CULTURE");
}

function getCurrency(): string {
  return getEnv("VUE_APP_CURRENCY");
}

function getApiClientId(): string {
  return getEnv("VUE_APP_API_CLIENT_ID");
}

function getTypingInterval(): number {
  return Number.parseFloat(getEnv("VUE_APP_TYPING_INTERVAL"));
}

function getGtmCode(): string {
  return getEnv("VUE_APP_GTM_CODE");
}

function getOptId(): string {
  return getEnv("VUE_APP_OPT_ID");
}

function getCategoriesStatusInterval(): number {
  return +getEnv("VUE_APP_CATEGORIES_STATUS_INTERVAL_MINUTES");
}

export {
  getApiBaseUrl,
  getCulture,
  getCurrency,
  getApiClientId,
  getTypingInterval,
  getGtmCode,
  getOptId,
  getCategoriesStatusInterval
};
