
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/module/api/service/Api';
import IBanner from '@/module/main/dto/IBanner';

@Component({
  components: {},
})
export default class HomePage extends Vue {
  private getShops(): void {
    Api.shops().then((shops) => {
      if (shops !== null) {
        this.$store.commit('setShops', shops);
      } else {
        console.error('Shops not returned.');
      }
    });
  }

  private loadBanners(): void {
    Api.banners().then((banners) => {
      if (banners !== null) {
        this.loadBannersFiles(this.prepareBannersIdObject(banners.banners));
      } else {
        console.error('Banners not returned.');
      }
    });
  }

  private prepareBannersIdObject(banners: IBanner[]): any {
    const bannersId: any = [];

    banners.forEach((banner: IBanner) => {
      bannersId.push({
        desktopId: Number.parseInt(banner.desktopImg.split('/', 10)[1]),
        mobileId: Number.parseInt(banner.mobileImg.split('/', 10)[1]),
        redirectUrl: banner.redirectUrl,
      });
    });

    return bannersId;
  }

  private loadBannersFiles(bannersId: any): void {
    // Here need load bannersID

    const promiseList: any = [];
    const banners: any = [];
    bannersId.forEach((banner: any) => {
      promiseList.push(Api.files(banner.mobileId));
      promiseList.push(Api.files(banner.desktopId));
    });

    Promise.all(promiseList).then((values) => {
      values.forEach((value, index) => {
        if (index % 2 === 0) {
          banners.push({ mobile: value, desktop: '', redirectUrl: bannersId[index/2].redirectUrl });
        } else {
          banners[banners.length - 1].desktop = value;
        }
      });
      this.$store.commit('setBanners', banners);
    });
  }

  public created(): void {
    this.getShops();
    this.loadBanners();
  }
}
