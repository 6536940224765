import Router, { RouteConfig } from 'vue-router';

import Name from './NameEnum';
import Path from './PathEnum';
import Vue from 'vue';

Vue.use(Router);

const routes: Array<RouteConfig> = [
  {
    path: Path.INSTRUCTIONS,
    name: Name.INSTRUCTIONS,
    component: () => import('@/module/main/page/Instructions.vue'),
  },
  {
    path: Path.MAIN,
    name: Name.MAIN,
    component: () => import('@/module/main/page/Home.vue'),
  },
  {
    path: Path.CATEGORY,
    name: Name.CATEGORY,
    component: () => import('@/module/main/page/Main.vue'),
  },
  {
    path: Path.PRODUCT,
    name: Name.PRODUCT,
    component: () => import('@/module/main/page/Product.vue'),
    props: {},
  },
  {
    path: Path.SEARCH,
    name: Name.SEARCH,
    component: () => import('@/module/main/page/Main.vue'),
    props: {},
  },

];
const router = new Router({
  mode: 'history',
  routes,
});

export default router;
