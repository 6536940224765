import ICategories from '@/module/main/dto/ICategories';
import ICategory from '@/module/main/dto/ICategory';

export default class CategoriesModel implements ICategories {
  constructor(
    public data: ICategory[],
  ) {}

  private _count: number = this.data.length;

  private getCount(): number {
    return this.data.length;
  }

  get count(): number {
    return this._count;
  }

  public getAll(): ICategory[] {
    return this.data;
  }

  public getFirstFive(): ICategory[] {
    if (this.count > 5) {
      return this.data.slice(0, 5);
    }
    return this.data;
  }

  public getRest(): ICategory[] {
    if (this.count > 5) {
      return this.data.slice(5);
    }
    return [];
  }

  public getRestIds(): number[] {
    const IDs: number[] = [];
    if (this._count > 5) {
      this.getRest().forEach((category) => {
        IDs.push(category.id);
      });
    }
    return IDs;
  }

  public getPriority() {
    return this.data[0];
  }

  public getCategory(id: number): ICategory {
    let foundCategory: ICategory | undefined;
    foundCategory = this.data.find(((category) => category.id === id));
    if (foundCategory === undefined) {
      foundCategory = this.getPriority();
    }
    return foundCategory;
  }
}
