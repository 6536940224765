import CategoriesModel from "@/module/main/model/CategoriesModel";

export type StoredCategoriesWithStatus = {
  categories: CategoriesModel,
  lastUpdate: Date | undefined,
  lastCheck: Date | undefined,
};

export default class LocalStorageAccessor {
  private static readonly CATEGORIES_STORAGE_KEY = 'hc-zdc:cats';

  public static storeCategories(categories: CategoriesModel, lastUpdate?: Date, lastCheck?: Date): void {
    localStorage.setItem(this.CATEGORIES_STORAGE_KEY, JSON.stringify({
      categories,
      lastUpdate,
      lastCheck,
    }));
  }

  public static loadCategories(): StoredCategoriesWithStatus | undefined {
    const serialized: string | null = localStorage.getItem(this.CATEGORIES_STORAGE_KEY);
    const categories: StoredCategoriesWithStatus | undefined = serialized
      ? JSON.parse(serialized)
      : undefined;
    if (categories) {
      categories.lastUpdate = new Date(categories.lastUpdate ?? 0)
      categories.lastCheck = new Date(categories.lastCheck ?? 0)
    }
    return categories;
  }
}
